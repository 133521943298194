<!--南美三点选择-->
<template>
  <div class="SaOneOrThree" v-show="isShow">
    <div v-show="orderType === 17">
      <el-radio v-model="promType" label="1" border style="display: block;margin-right: 0" @input="promTypeChange">
        <!--一个点-->
        <span style="font-size: 18px">{{ $t('po.rightQty.1') }} </span>
      </el-radio>
      <el-radio v-model="promType" label="10" border
                style="display: block;margin-left: 0;margin-top: 10px;margin-bottom: 10px"
                @input="promTypeChange">
        <!--三个点-->
        <span style="font-size: 18px">{{ $t('po.rightQty.3') }} [(500PV)x3]</span>
      </el-radio>
    </div>
    <div>
      <p>{{$t('LAN00001424.prompt')}}</p>
      <el-table
          border
          :data="XD_list"
          header-align="center"
          style="width: 100%"
      >
        <el-table-column prop="goodscode" align="center" :label="$t('BiGoods.goodsCode')"></el-table-column>
        <el-table-column prop="name" align="center" :label="$t('BiGoods.name')"></el-table-column>
        <el-table-column prop="pv" align="center" :label="$t('PV')"></el-table-column>
        <el-table-column prop="proPrice" align="center" :label="$t('pd.price')"></el-table-column>
        <el-table-column :label="$t('Common.buyNumber')" width="200" align="center">
          <template slot-scope="{ row }">
            <el-input-number
                v-model="row.quantity"
                size="mini"
                :min="0"
                style="width: 100px;"
                @change="validateQuantities"
            />
          </template>
        </el-table-column>
      </el-table>
      <el-table
          border
          :data="YJ_list"
          header-align="center"
          style="width: 100%"
      >
        <el-table-column prop="goodscode" align="center" :label="$t('BiGoods.goodsCode')"></el-table-column>
        <el-table-column prop="name" align="center" :label="$t('BiGoods.name')"></el-table-column>
        <el-table-column prop="pv" align="center" :label="$t('PV')"></el-table-column>
        <el-table-column prop="proPrice" align="center" :label="$t('pd.price')"></el-table-column>
        <el-table-column :label="$t('Common.buyNumber')" width="200" align="center">
          <template slot-scope="{ row }">
            <el-input-number
                v-model="row.quantity"
                size="mini"
                :min="0"
                style="width: 100px;"
                :disabled="row.goodscode === 'D014-10'"
                @change="validateQuantities"
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SaOneOrThree',
  directives: {},
  components: {},
  props: {
    // isShow: Boolean,
    goodsList: {
      type: Array,
      default: () => {
        return []
      }
    },
    orderType: {
      type: Number,
      default: 0
    },
    timeShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      LAN00001424_XD_num: 0,
      LAN00001424_YJ_num: 0,
      LAN00001424_XD_list: [],
      LAN00001424_YJ_list: [],
      LAN00001424_list: [],
      buy_LAN00001424_time: false,
      tableKey: 0,
      XD_list: [
        {
          name: 'Plantillas con aniones 35-36',
          goodsName: 'Plantillas con aniones 35-36',
          goodscode: 'D013-01',
          goodsCode: 'D013-01',
          quantity: 0,
          point: 0,
          price: 0,
          proPrice: 0,
          standardPriceB: 0,
          standardPv: 0,
          standardPrice: 0,
          pv: 0,
          isGive: 1,
          gift: '赠品',
        }, {
          name: 'Plantillas con aniones 37-38',
          goodsName: 'Plantillas con aniones 37-38',
          goodscode: 'D013-02',
          goodsCode: 'D013-02',
          quantity: 0,
          point: 0,
          price: 0,
          proPrice: 0,
          standardPriceB: 0,
          standardPv: 0,
          standardPrice: 0,
          pv: 0,
          isGive: 1,
          gift: '赠品',
        }, {
          name: 'Plantillas con aniones 39-40',
          goodsName: 'Plantillas con aniones 39-40',
          goodscode: 'D013-03',
          goodsCode: 'D013-03',
          quantity: 0,
          point: 0,
          price: 0,
          proPrice: 0,
          standardPriceB: 0,
          standardPv: 0,
          standardPrice: 0,
          pv: 0,
          isGive: 1,
          gift: '赠品',
        }, {
          name: 'Plantillas con aniones 41-42',
          goodsName: 'Plantillas con aniones 41-42',
          goodscode: 'D013-04',
          goodsCode: 'D013-04',
          quantity: 0,
          point: 0,
          price: 0,
          proPrice: 0,
          standardPriceB: 0,
          standardPv: 0,
          standardPrice: 0,
          pv: 0,
          isGive: 1,
          gift: '赠品',
        },{
          name:'Plantillas con aniones 43-44',
          goodsName:'Plantillas con aniones 43-44',
          goodscode:'D013-05',
          goodsCode:'D013-05',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          standardPriceB:0,
          standardPv:0,
          standardPrice:0,
          pv:0,
          isGive:1,
          gift:'赠品',
        },
      ],
      YJ_list: [
        {
          name: 'GAFAS BLOQUEADORAS DE LUZ AZUL (blanco)',
          goodsName: 'GAFAS BLOQUEADORAS DE LUZ AZUL (blanco)',
          goodscode: 'D014-10',
          goodsCode: 'D014-10',
          quantity: 0,
          point: 0,
          price: 0,
          proPrice: 0,
          standardPriceB: 0,
          standardPv: 0,
          standardPrice: 0,
          pv: 0,
          isGive: 1,
          gift: '赠品',
        }, {
          name: 'GAFAS BLOQUEADORAS DE LUZ AZUL',
          goodsName: 'GAFAS BLOQUEADORAS DE LUZ AZUL',
          goodscode: 'D014-20',
          goodsCode: 'D014-20',
          quantity: 0,
          point: 0,
          price: 0,
          proPrice: 0,
          standardPriceB: 0,
          standardPv: 0,
          standardPrice: 0,
          pv: 0,
          isGive: 1,
          gift: '赠品',
        },
      ],
      promType: null,
      // isShow: true,
      doLoading: false
    }
  },
  computed: {
    isShow() {
      let filterRes = this.goodsList.filter(item => item.goodsCode === 'LA-N000014-24')[0]
      let quantity = filterRes?.quantity || 0
      return !!(quantity && ( this.orderType === 17 || this.orderType === 18 ) && this.timeShow)
    },
    buy_LAN00001424_num() {
      const item = this.goodsList.find((item) => item.goodsCode === "LA-N000014-24");
      return item ? item.quantity : 0;
    },
  },
  watch: {
    'buy_LAN00001424_num': function (val) {
      if (val === 0) {
        this.XD_list.forEach((item) => (item.quantity = 0));
        this.YJ_list.forEach((item) => (item.quantity = 0));
        this.promType = ''
      }
      this.promTypeChange()
      this.validateQuantities()
    },
  },
  beforeCreate() {
  },
  created() {
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeUpdate() {
  },
  updated() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  activated() {
  },
  methods: {
    promTypeChange() {
      this.$emit('promTypeChange', this.promType)
    },
    validateQuantities() {
      // const LAN00001424_XDQuantity = this.XD_list.reduce((acc, item) => acc + item.quantity, 0);
      // const LAN00001424_YJQuantity = this.YJ_list.reduce((acc, item) => acc + item.quantity, 0);
      // if (LAN00001424_XDQuantity === this.buy_LAN00001424_num * 2 && LAN00001424_YJQuantity === this.buy_LAN00001424_num * 2) {
      //   const LAN00001424_XDList = this.XD_list.filter(item => item.quantity > 0);
      //   const LAN00001424_YJList = this.YJ_list.filter(item => item.quantity > 0);
      //   this.$emit('LAN00001424_valid', {XD_list: LAN00001424_XDList, YJ_list: LAN00001424_YJList});
      // } else {
      //   this.$emit('LAN00001424_invalid', 'The.quantity.of.products');
      // }
      const LAN00001424_XDList = this.XD_list.filter(item => item.quantity > 0);
      const LAN00001424_YJList = this.YJ_list.filter(item => item.quantity > 0);
      this.$emit('LAN00001424_valid', {XD_list: LAN00001424_XDList, YJ_list: LAN00001424_YJList});
    }
  }
}
</script>

<style lang="scss" scoped>
.SaOneOrThree {
  text-align: left;
}
</style>
